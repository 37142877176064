import {
  AnchorRouter,
  ContributionLogo,
  EmergencySavingsLogo,
  FynbosLogo,
  Icon,
  IconButton,
  Router,
  TFSALogo
} from '@/Components'
import { cn, useToast } from '@/Lib'
import { PageProps } from '@/types'
import { Head, Link, router, usePage } from '@inertiajs/react'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import * as Separator from '@radix-ui/react-separator'
import { posthog } from 'posthog-js'
import { FC, ReactElement, ReactNode, useEffect, useMemo } from 'react'
import { Toaster } from 'sonner'
import { NavDrawer } from './NavDrawer'

type AppLayoutProps = {
  title?: string
  actions?: ReactNode
  children?: ReactElement
}

export const AppLayout: FC<AppLayoutProps> = ({ title, actions, children }) => {
  const { hide_amounts, auth } = usePage<PageProps>().props
  useToast()

  const _handle = () => {
    router.post(
      route('toggle-hide-amounts'),
      {},
      {
        preserveScroll: true
      }
    )
  }

  useEffect(() => {
    posthog.identify(auth.user.id.toString(), { name: auth.user.name })
  }, [auth.user.id, auth.user.name])

  return (
    <>
      <Head title={title} />
      <div className='relative inset-0 flex min-h-dvh w-full flex-col bg-app lg:flex-row'>
        <nav className='hidden h-full w-72 lg:fixed lg:flex lg:flex-col'>
          <div className='m-4 flex items-center justify-between'>
            <Link href='/'>
              <FynbosLogo />
            </Link>
            <IconButton onClick={_handle}>
              {hide_amounts ? 'visibility_off' : 'visibility'}
            </IconButton>
          </div>

          <ScrollArea.Root className='h-full w-full overflow-hidden'>
            <ScrollArea.Viewport className='h-full w-full'>
              <div className='flex max-w-72 flex-col gap-1 p-4'>
                <NavItem href={route('home')}>
                  <Icon>home</Icon>Home
                </NavItem>
                <NavItem href={route('contribution.index')}>
                  <ContributionLogo className='p-0.5' />
                  Contributions
                </NavItem>
                <NavItem href={route('emergency-savings.index')}>
                  <EmergencySavingsLogo className='p-0.5' />
                  Emergency savings
                </NavItem>
                <NavItem href={route('tfsa.index')}>
                  <TFSALogo className='p-0.5' />
                  Tax-free investments
                </NavItem>
                <NavItem href={route('transactions.index')}>
                  <Icon>receipt</Icon>Transactions
                  {/*TODO Figure out a way to have notifications here */}
                  {/*<span className='absolute right-2 top-2 size-2 rounded-full bg-red-500' />*/}
                </NavItem>
                <NavItem href={route('settings.index')}>
                  <Icon>settings</Icon>Settings
                </NavItem>
              </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              className='flex touch-none select-none p-0.5 transition-colors duration-[160ms] ease-out hover:bg-slate-50 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col'
              orientation='vertical'
            >
              <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-slate-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
          <div className='flex flex-col gap-1 p-4'>
            <NavItem href={route('learn')}>
              <Icon>local_library</Icon>Learn
            </NavItem>
            <AnchorRouter
              target='_blank'
              href='https://wa.me/27686244900'
              className='relative flex w-full items-center gap-2 truncate rounded-xl p-3 font-normal text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
            >
              <Icon>support</Icon>Contact support
            </AnchorRouter>
            <Separator.Root decorative className='h-px w-full bg-slate-200' />
            <LogoutNavItem>
              <Icon>logout</Icon>Logout
            </LogoutNavItem>
          </div>
        </nav>
        <header className='sticky top-0 z-40 flex w-full border-b border-slate-200 bg-white/95 lg:hidden [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur'>
          <div className='mx-auto flex w-full items-center gap-x-4 p-4 md:max-w-4xl'>
            <NavDrawer>
              <NavItem href={route('home')}>
                <Icon>home</Icon>Home
              </NavItem>
              <NavItem href={route('contribution.index')}>
                <ContributionLogo className='p-0.5' />
                Contributions
              </NavItem>
              <NavItem href={route('emergency-savings.index')}>
                <EmergencySavingsLogo className='p-0.5' />
                Emergency savings
              </NavItem>
              <NavItem href={route('tfsa.index')}>
                <TFSALogo className='p-0.5' />
                Tax-free investments
              </NavItem>
              <NavItem href={route('transactions.index')}>
                <Icon>receipt</Icon>Transactions
              </NavItem>
              <NavItem href={route('settings.index')}>
                <Icon>settings</Icon>Settings
              </NavItem>

              <div className='mt-auto' />
              <NavItem href={route('learn')}>
                <Icon>local_library</Icon>Learn
              </NavItem>
              <AnchorRouter
                target='_blank'
                href='https://wa.me/27686244900'
                className='relative flex w-full items-center gap-2 truncate rounded-xl p-3 font-normal text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
              >
                <Icon>support</Icon>Contact support
              </AnchorRouter>
              <Separator.Root decorative className='h-px w-full bg-slate-200' />
              <LogoutNavItem>
                <Icon>logout</Icon>Logout
              </LogoutNavItem>
            </NavDrawer>
            <div className='flex w-full items-center justify-between'>
              <Link href='/'>
                <FynbosLogo />
              </Link>
              <IconButton onClick={_handle}>
                {hide_amounts ? 'visibility_off' : 'visibility'}
              </IconButton>
            </div>
          </div>
        </header>
        <div className='flex min-h-dvh w-full flex-col gap-y-6 px-4 lg:pl-[19rem]'>
          <main className='mx-auto min-h-lvh w-full pt-4 lg:max-w-4xl lg:pt-20'>
            {(title || actions) && (
              <div className='z-10 flex w-full bg-app p-4 lg:sticky lg:top-0'>
                <h1 className='text-xl font-medium text-strong'>{title}</h1>
                <div className='ml-auto'>{actions}</div>
              </div>
            )}
            {children}
          </main>
          <footer className='mx-auto mt-auto flex w-full flex-col space-y-2 py-20 text-xs text-medium lg:max-w-4xl'>
            <p>&copy; 2024 Fynbos Inc</p>
          </footer>
        </div>
      </div>
      <Toaster expand position='top-right' offset={16} />
    </>
  )
}

type NavItemProps = {
  children?: ReactNode
  className?: string
  href: string
}

const NavItem: FC<NavItemProps> = ({ children, href, className }) => {
  const { url } = usePage()

  const isActive = useMemo(() => {
    const active = new URL(url, window.location.origin)
    const current = new URL(href, window.location.origin)

    return active.pathname.startsWith(current.pathname)
  }, [url, href])

  return (
    <Router
      href={href}
      className={cn(
        isActive ? 'bg-nav-active' : 'hover:bg-nav',
        'relative flex w-full items-center gap-2 truncate rounded-xl p-3 text-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        className
      )}
    >
      {children}
    </Router>
  )
}

NavItem.displayName = 'ListItem'

type LogoutNavItemProps = {
  children?: ReactNode
}

const LogoutNavItem: FC<LogoutNavItemProps> = ({ children }) => {
  const _handleLogout = () => {
    router.post(
      '/logout',
      {},
      {
        onSuccess: () => {
          posthog.reset()
        }
      }
    )
  }

  return (
    <div
      onClick={_handleLogout}
      className='relative flex w-full cursor-pointer items-center gap-2 truncate rounded-xl p-3 text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
    >
      {children}
    </div>
  )
}

LogoutNavItem.displayName = 'LogoutNavItem'

type HideAmountsNavItemProps = {
  children?: ReactNode
}

const HideAmountsNavItem: FC<HideAmountsNavItemProps> = ({ children }) => {
  const _handle = () => {
    router.post(route('toggle-hide-amounts'))
  }

  return (
    <div
      onClick={_handle}
      className='relative w-full cursor-pointer truncate rounded-xl p-3 text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
    >
      {children}
    </div>
  )
}

HideAmountsNavItem.displayName = 'HideAmountsNavItem'
